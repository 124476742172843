$width: 192px !default;

$width-xl: 512px !default;
$width-lg: 256px !default;
$width-md: $width !default;
$width-sm: 128px !default;
$width-xs: 96px !default;

$height: 48px !default;

$height-xxx: 400px !default;
$height-xx: 256px !default;
$height-xl: 128px !default;
$height-lg: 64px !default;
$height-md: $height !default;
$height-sm: 16px !default;
$height-xs: 8px !default;
$height-xxs: 4px !default;

$spacer: 2em !default;

$spacer-xxx: 36em !default;
$spacer-xx: 16em !default;
$spacer-xl: 8em !default;
$spacer-lg: 4em !default;
$spacer-md: $spacer !default;
$spacer-sm: 1em !default;
$spacer-xs: 0.5em !default;
$spacer-xxs: 0.25em !default;

$screen-2k: 1920px;
$screen-xx: 1600px; //90rem;
$screen-lg: 1200px;//75rem;	// 1200px
$screen-md: 992px;//62rem;	// 992px
$screen-sm: 768px;//48rem;	// 768px
$screen-xs: 640px;//40rem;
$screen-xxs: 560px;//35rem;

/**
 * Components
 * -------------------------------------------------------------------
 * Collection of component sizes
 */
