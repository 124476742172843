.t-gap--top-xl {
	margin-top: $spacer-xl;
}
.t-gap--left-xl {
	margin-left: $spacer-xl;
}
.t-gap--right-xl {
	margin-right: $spacer-xl;
}
.t-gap--bottom-xl {
	margin-bottom: $spacer-xl;
}

.t-gap--top-lg {
	margin-top: $spacer-lg;
}
.t-gap--left-lg {
	margin-left: $spacer-lg;
}
.t-gap--right-lg {
	margin-right: $spacer-lg;
}
.t-gap--bottom-lg {
	margin-bottom: $spacer-lg;
}

.t-gap--top {
	margin-top: $spacer;
}
.t-gap--left {
	margin-left: $spacer;
}
.t-gap--right {
	margin-right: $spacer;
}
.t-gap--bottom {
	margin-bottom: $spacer;
}

.t-gap--top-sm {
	margin-top: $spacer-sm;
}
.t-gap--left-sm {
	margin-left: $spacer-sm;
}
.t-gap--right-sm {
	margin-right: $spacer-sm;
}
.t-gap--bottom-sm {
	margin-bottom: $spacer-sm;
}

.t-gap--top-xs {
	margin-top: $spacer-xs;
}
.t-gap--left-xs {
	margin-left: $spacer-xs;
}
.t-gap--right-xs {
	margin-right: $spacer-xs;
}
.t-gap--bottom-xs {
	margin-bottom: $spacer-xs;
}
