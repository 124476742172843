.a-image {
	@include preventSelect();
	position: relative;
	overflow: hidden;
}

.a-image--logo {
	width: 77px;
}

.a-image--background {
	z-index: $z-index-background;
}

.a-image--relative {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	.a-image--relative-image {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
