.m-navigation {
	@include equalSize(100%);
	@include flexBox(row, flex-end);
	position: relative;
	z-index: 3;

	.navigation--mobile-control {
		@include positionFixed($spacer-sm, 100vw);
		transform: translatex(-100%);
		padding-right: $spacer-sm;
		z-index: 4;

		@include at($screen-xs) {
			left: calc(100vw - #{$spacer-sm})
		}
	}

	.navigation--links {
		display: none;
		padding-top: $spacer;

		z-index: $z-index-nav-dropdown;

		&.active {
		  	padding: $spacer;
			@include flexBox(column, center, flex-start);
			z-index: 2;
			width: 100%;
			height: 100vh;
			position: fixed;
			top: 0;
			background-color: #F9F9F5;
		}

		.navigation--links-pages {
			width: 100%;
			@include flexBox(column, center, center);

			& > * {
				margin-bottom: $spacer-sm;
				width: 100%;
				text-align: left;
				padding-bottom: $spacer-xxs;
			}
		}
	}

	@include at($screen-md) {
		.navigation--mobile-control {
			display: none;
		}

		.navigation--links {
			@include equalSize(100%);
			@include flexBox(row, center, center);
			position: relative;
			top: 0;

			&.active {
				@include flexBox(row);
				padding: unset;
			}

			.navigation--links-pages {
				@include flexBox(row, center, center);
				width: unset;

				& > *:not(:nth-last-child(1)) {
					margin-right: $spacer-xl;

					@include to($screen-lg) {
						margin-right: $spacer-lg;
					}
				}

				& > * {
					width: unset;
				}
			}
		}
	}
}

.container {
	display: inline-block;

	.bar1, .bar2, .bar3 {
		width: 35px;
		height: 3px;
		background-color: #3D452B;
		margin: 6px 0;
		transition: 0.4s;
	}

	&:hover {
		.bar1, .bar2, .bar3 {
			background-color: #5C9B3D;
		}
	}
}

.active .bar1 {
	-webkit-transform: rotate(-45deg) translate(-5px, 5px);
	transform: rotate(-45deg) translate(-5px, 5px);
	background-color: #5C9B3D;
}

.active .bar2 {
	opacity: 0;
	background-color: #5C9B3D;
}

.active .bar3 {
	transform: rotate(45deg) translate(-8px, -8px);
	-webkit-transform: rotate(45deg) translate(-8px, -8px);
	background-color: #5C9B3D;
}

.closed .bar1 {
	-webkit-transform: unset;
	transform: unset;
	background-color: #3D452B;
}

.closed .bar2 {
	opacity: 1;
	background-color: #3D452B;
}

.closed .bar3 {
	transform: unset;
	-webkit-transform: unset;
	background-color: #3D452B;
}

.header-logo {
	position: unset;
	z-index: 1;
	.a-image--logo {
		height: 45px;
		width: 45px;
		margin-left: $spacer-sm;
		margin-top: $spacer-sm;
	}

}

@include at($screen-md) {
	.header-logo {
		display: none;
	}
}
