/**
 * -------------------------------------------------------------------
 * Collection of grayscale colors
 */
$brand-color--primary: #002140 !default;
$brand-color--dark: #2B3F7A !default;
$brand-color--light: #6CA4D9 !default;

$error-color: #de5050 !default;
$error-color--light: rgba($error-color, 0.1);
$success-color: #2eb81f !default;

/**
 * GRAYSCALE COLOR PALETTE
 * -------------------------------------------------------------------
 * Collection of grayscale colors
 */

$black: #000000 !default;
$grey: grey;
$grey--shadow: #dadada;
$grey--light: #f8f8f8;

$white--light: #fefefe;
$white: #fff !default;

$blue: #83DDEB;
$blue-active: #ECF6FB;
$blue-light: #F6FAFC;

$khaki: #3D452B;
