%sideFocusIndicator {
	content: "";
	@include size($spacer-xs, 100%);
	@include positionAbsolute();

	background: $brand-color--primary;
}

%sideFocusIndicatorAnimated {
	content: "";
	@include size($spacer-xs, 100%);
	@include positionAbsolute();

	background: $brand-color--primary;

	&:active {
		animation: resizeAnimationClickableRowPointer 6s ease-out forwards;

		@include keyframes (resizeAnimationClickableRowPointer) {
			0% { transform: scaleY(1) }
			5% { transform: scaleY(0.8) }
			100% { transform: scaleY(0.01) }
		}
	}
}
