.t-padding--top-xl {
	padding-top: $spacer-xl;
}
.t-padding--left-xl {
	padding-left: $spacer-xl;
}
.t-padding--right-xl {
	padding-right: $spacer-xl;
}
.t-padding--bottom-xl {
	padding-bottom: $spacer-xl;
}

.t-padding--top-lg {
	padding-top: $spacer-lg;
}
.t-padding--left-lg {
	padding-left: $spacer-lg;
}
.t-padding--right-lg {
	padding-right: $spacer-lg;
}
.t-padding--bottom-lg {
	padding-bottom: $spacer-lg;
}

.t-padding--top {
	padding-top: $spacer;
}
.t-padding--left {
	padding-left: $spacer;
}
.t-padding--right {
	padding-right: $spacer;
}
.t-padding--bottom {
	padding-bottom: $spacer;
}

.t-padding--top-sm {
	padding-top: $spacer-sm;
}
.t-padding--left-sm {
	padding-left: $spacer-sm;
}
.t-padding--right-sm {
	padding-right: $spacer-sm;
}
.t-padding--bottom-sm {
	padding-bottom: $spacer-sm;
}

.t-padding--top-xs {
	padding-top: $spacer-xs;
}
.t-padding--left-xs {
	padding-left: $spacer-xs;
}
.t-padding--right-xs {
	padding-right: $spacer-xs;
}
.t-padding--bottom-xs {
	padding-bottom: $spacer-xs;
}

.t-padding--top-none {
	padding-top: 0;
}
.t-padding--left-none {
	padding-left: 0;
}
.t-padding--right-none {
	padding-right: 0;
}
.t-padding--bottom-none {
	padding-bottom: 0;
}
