$border-radius--lg: 1em !default;
$border-radius: .25em !default;
$border-radius--xs: .05em !default;
$border-radius--sm: .15em !default;
$border-radius--circle: 50%;

$box-shadow: 0 0 2px 0px transparentize($black, 0.5);
$box-shadow--sm: 0 0 1px 0px transparentize($black, 0.5);
$box-shadow--hover: 0 0 8px 0px transparentize($black, 0.5);

$border: 1px solid #5EB672;

$z-index-background-low: -2;
$z-index-background: -1;
$z-index-basic: 0;
$z-index-level-up: 1;
$z-index-drop-down: 10;
$z-index-navigation-banner: 49;
$z-index-navigation: 50;
$z-index-tooltip: 150;
$z-index-pop-up: 250;
$z-index-overlay: 2500;
$z-index-nav-dropdown: 50000;
