
body {
	color: $khaki;
	-webkit-text-fill-color: currentColor;
	font-size: 1.4rem;
}

%font-size-xs,
.font-size-xs {
	font-size: 0.8rem;
}
%font-size-sm,
.font-size-sm {
	font-size: 1.2rem;
}
%font-size-md,
.font-size-md {
	font-size: 1.8rem;
}

%font-size-lg,
.font-size-lg {
	font-size: 2.2rem;
}

%font-size-xl,
.font-size-xl {
	font-size: 2.8rem;
}

%semi-bold,
.semi-bold {
	font-weight: 600;
}

%bold,
.bold {
	font-weight: bold;
}

%overflow-text,
.overflow-text {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

::selection {
	color: $white;
	-webkit-text-fill-color: currentColor;
	background: $brand-color--primary;
}
::-moz-selection {
	color: $white;
	-webkit-text-fill-color: currentColor;
	background: $brand-color--primary;
}
