$transition-fast: 0.3s ease;
$transition-default: 0.6s ease;
$transition-slow: 1.2s ease;
$transition-lazy: 2.4s ease;

$delay-short: 2s;
$delay: 5s;
$delay-long: 10s;

$iteration-default: 5;
$iteration-long: 10;
