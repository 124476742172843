.m-form--contact {
  form {
    @include flexBox(column, flex-start, flex-start);

	  @include to($screen-xs) {
		  padding: $spacer-sm;
	  }

    & > *:not(:nth-last-child(1)) {
      margin-bottom: $spacer-sm;
    }

    input, textarea {
      width: 400px;
      height: 44px;
      background-color: $blue-light;
      border: none;
      padding-left: $spacer;

		@include to($screen-xs) {
			width: 100%;
		}
    }

    input:focus {
		outline: 3px solid $blue;
		background-color: $blue-active;
    }

    textarea:focus {
      outline: 3px solid $blue;
    }

    #message {
      height: 114px;
      padding-top: $spacer-sm;
    }

	  label {
		font-family: Lulo, sans-serif;
	  }

    .m-form--contact-submit {
      font-family: Lulo, sans-serif;
      background-color: $blue;
		color: white;
		-webkit-appearance: none;
		-webkit-border-radius: none;
		border-radius: 20px;
		width: 50%;
		padding-left: unset;

      @include flexBox(column, center, center);
      cursor: pointer;
      transition: $transition-fast;

      &:hover {
        transition: $transition-fast;
        opacity: 0.5;
      }
    }
  }
}

.m-form--mailchimp {
	@include flexBox(column, flex-start, flex-start);

	@include to($screen-xs) {
		padding: $spacer-sm;
	}

	& > *:not(:nth-last-child(1)) {
		margin-bottom: $spacer-sm;
	}

	input, textarea {
		width: 400px;
		height: 44px;
		background-color: $blue-light;
		border: none;
		padding-left: $spacer;

		@include to($screen-xs) {
			width: 100%;
		}
	}

	input:focus {
		outline: 3px solid $blue;
		background-color: $blue-active;
	}

	textarea:focus {
		outline: 3px solid $blue;
	}

	#message {
		height: 114px;
		padding-top: $spacer-sm;
	}

	label {
		font-family: Lulo, sans-serif;
	}

	button {
		font-family: Lulo, sans-serif;
		background-color: $blue;
		color: white;
		-webkit-appearance: none;
		-webkit-border-radius: none;
		border-radius: 20px;
		width: 40%;
		padding-left: unset;
		height: 40px;

		@include flexBox(column, center, center);
		cursor: pointer;
		transition: $transition-fast;

		&:hover {
			transition: $transition-fast;
			opacity: 0.5;
		}
	}
}
