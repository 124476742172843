.o-section {
	width: 100%;
	@include flexBox(column);

	&[data-order="reverse"] {
		flex-direction: row-reverse;
	}

	@include to($screen-sm) {
		flex-direction: column;
	}
}

.o-section--cta-button {
	background-color: #5EB672;
	color: white;
	width: fit-content;
	padding: $spacer;
	margin-top: $spacer-lg;
	transition: $transition-fast;

	&:hover {
		transition: $transition-fast;
		opacity: 0.5;
	}
}

.o-section--home-hero {
	z-index: $z-index-level-up;

	.o-section--home-hero-graphic {
		margin-top: -20em;
		width: 100%;

		@include to($screen-lg) {
			margin-top: -$spacer-xx;
		}

		@include to($screen-md) {
			margin-top: -$spacer-lg;
		}
	}
}

.o-section--home-filosofie {
	background-color: #E2EDE0;
	padding: $spacer-lg;

	@include to($screen-md) {
		padding: $spacer;

	}

	.o-section--home-filosofie-content {
		@include flexBox(row, center, center);
		width: 100%;
		max-width: $screen-md;

		.green {
			p {
				color: #5C9B3D !important;
			}
		}
	}
}

.o-section-home-blog {
	position: relative;

	.o-section--home-blog-graphic {
		height: 400px;
		position: absolute;
		z-index: 99;

		@include to($screen-md) {
			z-index: -1;
		}
	}

	.o-section--home-blog-graphic-letter {
		position: absolute;
		height: 80%;
		top: 10%;
		left: 92%;
		z-index: -1;

		@include to($screen-md) {
			left: 75%;
		}
	}

	.o-section--home-blog-content {
		@include flexBox(row);
		height: 500px;
		padding: $spacer-lg 0;
		max-width: $screen-md;
		margin: $spacer-xl 0;

		@include to($screen-md) {
			height: auto;
			flex-direction: column;
			padding: $spacer;
		}

		.o-section--home-blog-content-image {
			width: 450px;
			height: 500px;

			@include to($screen-md) {
				height: auto;
				width: 100%;
				flex-direction: column;
				padding-bottom: $spacer;
			}
		}

		.o-section--home-blog-content-inside {
			margin-left: $spacer-lg;
			min-height: 100%;
			width: fit-content;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@include to($screen-md) {
				margin-left: unset;
			}

			.o-section--home-blog-content-title {
				font-family: Lulo, sans-serif;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 18px;
				text-align: left;

				@include to($screen-md) {
					margin-bottom: $spacer-sm;
				}
			}

			.body {
				font-family: Sofia, sans-serif;
				font-size: 15px;
				text-align: left;
				line-height: 120%;
				font-weight: 400;

				@include to($screen-md) {
					margin-bottom: $spacer-sm;
				}
			}

			.a-link-cta {
				@include to($screen-md) {
					margin-top: $spacer-sm;
				}
			}
		}
	}
}

.o-section-home-bossen {
	background-color: #9FD3AA;
	padding: $spacer-lg $spacer-xl;

	.o-section-home-bossen-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		height: fit-content;

		@include to($screen-md) {
			padding: $spacer $spacer;
			flex-direction: column;
		}
	}

	@include to($screen-md) {
		padding: $spacer $spacer;
	}

	.o-section-home-bossen-titel {
		font-family: Lulo, sans-serif;
		font-size: 33px;
		text-align: left;
		color: white;
		margin-top: -$spacer-sm;

		@include to($screen-xs) {
			font-size: 25px;
		}

		@include to($screen-md) {
			margin-top: unset;
			align-self: flex-start;
		}
	}

	.o-section-home-bos {
		height: 100%;
		margin-top: $spacer;

		.o-section-home-bos-title {
			font-family: Lulo, sans-serif;
			color: white;
			font-size: 16px;
		}

		.o-section-home-bos-graphic {
			height: 105px;
			margin-bottom: $spacer-sm;

			@include to($screen-md) {
				height: 105px;
			}
		}
	}

	.disclaimer {
		align-self: flex-end;
		margin-top: $spacer;
		font-family: Lulo, sans-serif;
		color: white;
		font-size: 10px;

		@include to($screen-md) {
			align-self: unset;
		}
	}
}

.o-section--contact {
	form {
		margin-right: unset;

		@include to($screen-md) {
			margin-top: $spacer;
			width: 100%;
		}
	}

	.o-section--contact-content {
		padding: $spacer-xl;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;

		::placeholder {
			font-family: "Lulo", sans-serif;
			color: $khaki;
		}

		@include to($screen-md) {
			flex-direction: column;
		}

		@include to($screen-xs) {
			padding: $spacer;
		}

		.o-section--contact-form {
			margin-top: 100px;

			@include to($screen-md) {
				width: 100%;
			}
		}


		.o-section--contact-content-body {
			width: 40%;
			margin-right: $spacer-xl;

			@include to($screen-md) {
				width: 100%;
				margin-right: unset;
			}

			.o-section--contact-content-body-text {

				p {
					font-family: Sofia, serif;
					text-align: left;
					font-weight: 400;
					font-size: 17px;
					margin-bottom: $spacer-xxs;
				}

			}

			.o-section--contact-content-body-hook {
				font-family: "Taviraj", serif;
				font-size: 20px;
				text-align: left;
				margin-bottom: $spacer-xs;
			}

			.o-section--contact-content-body-title {
				font-family: "Lulo", sans-serif;
				width: fit-content;
				position: relative;
				font-size: 45px;
				color: $blue;
				margin-bottom: $spacer-sm;

				@include to($screen-xs) {
					font-size: 30px;
				}

				&:after {
					content: '';
					position: absolute;
					bottom: 8px;
					left: -4%;
					width: 105%;
					height: 25%;
					background-color: rgba($blue, 0.4);
				}
			}
		}
	}

	.o-section--contact-graphic {
		margin-top: -14em;
		max-width: 100%;

		@include to($screen-md) {
			margin-top: -10em;
		}
	}
}

.o-section--cta {
	.o-section--cta-content {
		padding: $spacer-xl;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@include to($screen-xs) {
			padding: $spacer;
		}

			.o-section--cta-content-title {
				font-family: "Lulo", sans-serif;
				width: fit-content;
				position: relative;
				font-size: 45px;
				color: #5C9C3D;
				z-index: 1;

				@include to($screen-xs) {
					font-size: 25px;
				}

				&:after {
					content: '';
					position: absolute;
					bottom: 8px;
					left: -4%;
					width: 105%;
					height: 25%;
					background-color: rgba(#5EB672, 0.6);
					z-index: -1;

					@include to($screen-xs) {
						display: none;
					}
				}
			}

		.o-section--cta-content-description {
			font-family: Sofia, serif;
			width: 60%;
			text-align: center;
			margin-top: $spacer;

			@include to($screen-md) {
				width: 100%;
			}
		}

	}

	.o-section--cta-graphic {
		margin-top: -24em;

		@include to($screen-md) {
			margin-top: -6em;
		}
	}

	.o-section--cta-button {
		background-color: #5EB672;
		color: white;
		width: unset;
		padding: $spacer;
		margin-top: $spacer-lg;
		transition: $transition-fast;

		&:hover {
			transition: $transition-fast;
			opacity: 0.5;
		}
	}
}

.o-section--over-ons-hero {
	padding: $spacer-xl;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: row;
	position: relative;

	@include to($screen-md) {
		flex-direction: column-reverse;
		padding: $spacer;
	}

	.o-section--over-ons-header {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		width: fit-content;

		.o-section--over-ons-title {
			font-family: "Lulo", sans-serif;
			width: 8em;
			position: relative;
			font-size: 45px;
			color: #D87424;
			text-align: left;

			@include to($screen-xs) {
				font-size: 25px;
			}

			&:after {
				content: '';
				position: absolute;
				bottom: 8px;
				left: -2%;
				width: 100%;
				height: 25%;
				background-color: rgba(#D87424, 0.6);
				z-index: -1;
			}
		}

		h2 {
			font-family: "Lulo", sans-serif;
			font-size: 13.5px;
			text-align: left;
			margin-top: $spacer-sm;
			width: 20%;

			@include to($screen-md) {
				margin-bottom: $spacer;
			}
		}

		span {
			color: #D87424;
		}

		p {
			font-family: "Taviraj", serif;
			font-size: 30px;
			text-align: left;
			margin-top: $spacer-lg;
			max-width: 50%;
			margin-left: $spacer;
			background-color: rgba(#FFFFFF, 0.6);
			padding: $spacer-sm;
			position: absolute;
			line-height: 1.2em;

			@include to($screen-md) {
				position: relative;
				max-width: 100%;
				margin-left: unset;
				margin-top: unset;
				padding: unset;
				font-size: 20px;
			}
		}
	}

	.o-section--over-ons-image {
		float: right;
		width: 100%;
		z-index: -9;
	}
}

.subtext {
	display: flex;
	justify-content: center;
	font-family: Sofia, sans-serif;
	font-weight: 400;
	line-height: 1.5em;

	.padding {
		padding: 0 $spacer-xl;

		@include to($screen-md) {
			width: 100%;
			padding: $spacer;
			text-align: left;
		}
	}

	margin-bottom: $spacer-xl;

	@include to($screen-md) {
		margin-bottom: unset;
	}
}


.o-section--over-ons-hoe {
	padding: $spacer-xl;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	text-align: left;
	margin-top: -$spacer-xl;

	li {
		list-style: unset;

		margin: unset;
	}

	@include to($screen-md) {
		padding: $spacer;
		margin-top: -$spacer;
	}

	.m-article--section-title {
		font-family: "Lulo", sans-serif;
		width: fit-content;
		position: relative;
		font-size: 45px;
		color: #5FB672;
		margin-bottom: $spacer-xs;
		z-index: 1;

		&:after {
			content: '';
			position: absolute;
			bottom: 8px;
			left: -4%;
			width: 105%;
			height: 25%;
			background-color: #CAE3C9;
			z-index: -1;

			@include to($screen-md) {
				display: none;
			}
		}
	}

	span {
		color: #D87424;
	}

	p {
		font-family: Sofia, sans-serif;
		font-weight: 400;
		line-height: 1.5em;
		font-size: 15px;
	}

	.subtitle {
		font-family: Lulo, sans-serif;
		color: #D87424;
		font-size: 15px;
		margin-top: $spacer;
		margin-bottom: $spacer-xs;

	}

	h3 {
		margin-top: $spacer-md;
		margin-bottom: $spacer-xs;
		font-family: "Taviraj", serif;
		font-size: 30px;
		line-height: 1.2em;
		text-align: left;
	}
}

.o-section--over-ons-team {
	padding: $spacer-lg $spacer-xl;
	background-color: #F9F9F5;

	@include to($screen-md) {
		padding: $spacer-lg $spacer;
	}


	.team-container {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: row;
		flex-wrap: wrap;

		@include to($screen-md) {
			flex-direction: column;
		}
	}

	.o-section--team {
		margin-right: $spacer-lg;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 400px;
		margin-bottom: $spacer-lg;


		&:last-child {
			margin-right: 0px;
		}


		@include to($screen-md) {
			margin-right: unset;
			margin-bottom: $spacer-lg;
			align-items: flex-start;
			text-align: left;
			max-width: 100%;
			width: 100%;

			&:last-child {
				margin-bottom: unset;
			}
		}
	}

	.o-section--team-img {
		border-radius: 50%;
		width: 18em;
		height: 18em;

		@include to($screen-md) {
			margin-bottom: $spacer;
			width: 100%;
			border-radius: unset;
		}
	}

	.o-section--team-name-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		margin-top: $spacer;

		@include to($screen-md) {
			align-items: flex-start;
		}

		.name {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			@include to($screen-md) {
				align-items: flex-start;
			}
		}

		@include to($screen-md) {
			align-items: flex-start;
		}
	}

	.o-section--team-name {
		font-family: Lulo, sans-serif;
		width: fit-content;
		font-size: 20px;
		color: #5C9B3D;
		position: relative;
		z-index: 1;

		&:after {
			content: '';
			position: absolute;
			bottom: 0px;
			left: -4%;
			width: 105%;
			height: 50%;
			background-color: rgba(#5EB672, 0.6);
			z-index: -1;
		}
	}

	h2 {
		font-family: "Lulo", sans-serif;
		font-size: 13.5px;
		text-align: left;
		margin-top: $spacer-sm;
		margin-bottom: $spacer-lg;
		align-self: flex-start;
	}

	span {
		color: #FFAE27;
	}

	.subtext {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: $spacer-sm;
		margin-bottom: unset;
		font-size: 13px;
		width: 80%;
		align-self: center;

		@include to($screen-md) {
			width: 100%;
		}

	}
}

.o-section--blog-hero {
	padding: $spacer $spacer-xl;
	max-width: $screen-lg;
	width: 100%;

	@include to($screen-md) {
		padding: $spacer;
	}

	h1 {
		font-family: "Lulo", sans-serif;
		width: fit-content;
		position: relative;
		font-size: 45px;
		color: #FFAE27;
		margin-bottom: $spacer-sm;

		&:after {
			content: '';
			position: absolute;
			bottom: 8px;
			left: -4%;
			width: 105%;
			height: 25%;
			background-color: rgba(#FFAE27, 0.4);
		}
	}

	p {
		font-family: "Taviraj", serif;
		font-size: 30px;
		text-align: left;
		max-width: 70%;
		line-height: 1.2em;
		border-left: 1px solid black;
		padding-left: $spacer-xs;
		margin-left: $spacer;

		@include to($screen-md) {
			font-size: 20px;
			max-width: 100%;
			margin-left: unset;
		}
	}

	.o-section--blog-hero-right {
		 height: 30em;
		float: right;
		margin-top: -$spacer-xl;
		margin-right: -$spacer-xl;
		z-index: -1;
	}

}

.o-section--blog-detail {
	max-width: $screen-xx;
	margin-top: $spacer;
	min-width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	.o-section--blog-detail-main {
		border-bottom-right-radius: 150px;
		width: 100%;
		height: 35em;
	}

	.main {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: $screen-lg;

		@include to($screen-md) {
			width: 100%;
		}

		.m-article--blog-card-content {
			margin-top: $spacer-lg;
			margin-bottom: $spacer-lg;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			align-items: flex-start;
			width: $screen-xs;

			@include to($screen-md) {
				width: 100%;
				padding: $spacer;
			}

			h2 {
				font-family: Lulo, sans-serif;
				color: #5DB672;
				margin-bottom: $spacer-xs;
			}

			.tag {
				font-family: Lulo, sans-serif;
				color: #F7AC29;
				border: 1px solid #F7AC29;
				width: fit-content;
				padding: $spacer-xxs $spacer-xs;
				margin-bottom: $spacer-xs;
				font-size: 13px;
			}

			.about {
				font-family: Sofia, sans-serif;
				font-size: 12px;
				text-transform: uppercase;
				margin-bottom: unset;
				color: #797A60;
			}

			.body {
				margin-top: $spacer;
				margin-bottom: $spacer;
				font-family: Sofia, sans-serif;
				font-size: 15px;
				font-weight: 400;
				line-height: 1.5em;
			}

		}
	}
}

.o-section--blog-detail-main-quote {
	width: 100%;
	display: flex;
	margin-top: $spacer;

	.quote {
		height: 200%;
		margin-top: -$spacer-sm;
	}

	//.right {
	//	-webkit-transform: scaleY(-1);
	//	transform: scaleY(-1);
	//	margin-top: 0.1em;
	//}

	.quote-content {
		font-size: 22px;
		line-height: 120%;
		margin-right: $spacer-sm;

		@include to($screen-md) {
			width: 100%;
		}
	}
}

.o-section-over-ons-modal {
	padding: $spacer-xl $spacer-xl;
	background-color: #F9F9F5;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 1;

	.o-section--home-blog-graphic {
		height: 400px;
		position: absolute;
		z-index: 1;
		top: 0;

		@include to($screen-md) {
			z-index: -1;
		}
	}

	@include to($screen-md) {
		padding: $spacer-lg $spacer;
	}


	.o-section--modal {
		margin-bottom: $spacer-xl;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		z-index: 2;
		width: 100%;
		max-width: $screen-md;

		span {
			color: #D87424;
		}

		&:last-child {
			margin-bottom: 0px;
		}

		@include to($screen-md) {
			width: 100%;
			align-items: flex-start;
		}

		@include to($screen-md) {
			margin-right: unset;
			margin-bottom: $spacer-lg;

			&:last-child {
				margin-bottom: unset;
			}
		}

		.o-section--title {
			font-family: "Lulo", sans-serif;
			font-size: 13.5px;
			text-align: left;
			margin-top: $spacer;
		}


		.subtext {
			margin-bottom: unset;
			font-family: Sofia, sans-serif;
			font-weight: 400;
			line-height: 1.5em;
			font-size: 15px;
			text-align: left;
			margin-top: $spacer-sm;
			display: flex;
			flex-direction: column;

			p {
				margin-bottom: $spacer-sm;
				font-size: 15px;
			}
		}

		.o-section--modal-img {
			width: 100%;
			height: 40em;
		}
	}
}

.o-section-blog-modal {

	button {
		min-width: 100%;
	}

	ul {
		padding: $spacer-xl $spacer-xl;
		background-color: #F9F9F5;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;

		@include to($screen-md) {
			flex-direction: column;
			padding: $spacer-lg $spacer;
		}
	}

	li {
		margin-right: $spacer-xl;
		padding-bottom: unset;
		width: 100%;

		&:last-child {
			margin-right: unset;
		}

		@include to($screen-md) {
			margin-right: unset;
		}
	}

	.m-article--blog-card {
		flex-direction: column;
		padding: unset;
		min-width: 100%;

		@include to($screen-md) {
			align-items: flex-start;
		}

		.m-article--blog-card-img {
			width: 100%;
			border-bottom-right-radius: unset;
		}


		.m-article--blog-card-content {
			padding: unset;
			width: unset;
			align-self: unset;
			padding-top: $spacer;

		}
	}
}

.container {
	width: 100%;
}


.o-section-ontdek {
	display: grid;
	grid-template-columns: repeat(2, calc(50% - 2em));
	grid-gap: $spacer-lg;
	padding: $spacer-xl $spacer-xl;
	background-color: #F9F9F5;


	@include to($screen-md) {
		padding: $spacer-lg $spacer;
		display: flex;
		flex-direction: column-reverse;
	}

	.o-section-ontdek-body {
		text-align: left;
		display: flex;
		flex-direction: column;

		h3 {
			margin-bottom: $spacer-xs;
			font-family: "Taviraj", serif;
			font-size: 35px;
			line-height: 1.2em;
			text-align: left;
		}

		.body {
			margin-top: $spacer;
			font-family: Sofia, sans-serif;
			font-size: 15px;
		}

		.o-section-ontdek-img-sub {
			width: calc(100% - 2em);
			height: 160px;
		}
	}

	.o-section-ontdek-img-main {
		width: calc(100% - 2em);
	}

	.o-section--cta-button {
		@include to($screen-md) {
			margin-bottom: $spacer;
		}
	}
}

.o-section-buy {
	.o-section-buy-body {
		padding: $spacer-xl;

		@include to($screen-md) {
			padding: $spacer-lg $spacer;
		}
	}

	.body {
		font-family: Sofia, sans-serif;
		font-size: 15px;
		text-align: left;
		line-height: 120%;
		font-weight: 400;
		padding: $spacer;

		@include to($screen-md) {
			padding: unset;
			margin-top: $spacer;
		}
	}


	.o-section--cta-content-title {
		font-family: "Lulo", sans-serif;
		width: fit-content;
		position: relative;
		font-size: 45px;
		color: #5C9C3D;

		@include to($screen-xs) {
			font-size: 25px;
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 8px;
			left: -4%;
			width: 105%;
			height: 25%;
			background-color: rgba(#5EB672, 0.6);
			z-index: -1;
		}
	}

	.o-section--cta-button {
		background-color: #5EB672;
		color: white;
		width: fit-content;
		padding: $spacer;
		margin-top: $spacer-lg;
		transition: $transition-fast;
		margin-bottom: $spacer-lg;

		&:hover {
			transition: $transition-fast;
			opacity: 0.5;
		}
	}

	.o-section--cta-graphic {
		margin-top: -24em;
		@include to($screen-md) {
			margin-top: -6em;
		}
	}

	.o-section-buy-images {
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-template-rows: 200px;
		grid-template-areas:
			"title one"
			"two one"
			"three one";
		max-width: $screen-md;
		height: 50em;
		grid-gap: $spacer-sm;
		margin-bottom: $spacer-lg;

		@include to($screen-md) {
			margin-bottom: unset;
		}

		@include to($screen-md) {
			display: flex;
			flex-direction: column;
			padding: $spacer-lg $spacer;
			height: unset;
		}

		.o-section-buy-img {
			overflow: hidden;
			max-width: 100%;
			max-height: 100%;
		}

		.title {
			grid-area: title;
			font-family: "Taviraj", serif;
			font-size: 30px;
			text-align: left;
			line-height: 1.2em;
			padding-right: $spacer;

			@include to($screen-md) {
				margin-bottom: $spacer;
			}
		}
	}
}


.o-section-checkout-list {
	max-width: $screen-lg;
	margin-top: $spacer-lg;
	padding: $spacer;
	display: flex;

	.o-section--checkout-back {
		font-family: "Lulo", sans-serif;
		justify-self: flex-start;
	}

	.line {
		margin-top: $spacer;
		width: 100%;
		border-bottom: 1px solid #3D452B;
	}

	.totaal {
		font-family: "Lulo", sans-serif;
		align-self: flex-end;
		margin-top: $spacer;
	}

	.o-section--cta-button {
		align-self: flex-end;
	}

	.o-section-checkout-list-wrapper {
		width: 100%;

		li {
			width: 100%;
		}
	}

	.checkout-buttons {
		margin-top: $spacer-lg;
		align-self: flex-end;
		display: flex;
		justify-content: center;
		align-items: center;

		@include to($screen-md) {
			flex-direction: column;
			align-self: unset;
		}

		.o-section--cta-button {
			margin-top: unset;
			margin-left: $spacer;
			align-self: unset;

			@include to($screen-md) {
				margin-top: $spacer;
			}

		}
	}
}


.o-section--checkout {
	margin-top: $spacer;
	align-self: flex-start;

	@include to($screen-sm) {
		align-self: unset;
	}

	.o-section--checkout-li {
		width: 100%;
		display: grid;
		grid-template-columns: 40px auto;
		align-items: center;
		column-gap: $spacer-sm;

		img {
			height: 40px;
		}
	}

	form {
		@include flexBox(column, flex-start, flex-start);
		width: 500px;

		@include to($screen-md) {
			width: 100%;
		}

		::placeholder {
			font-family: "Lulo", sans-serif;
			color: $khaki;
		}

		@include to($screen-xs) {
			padding: $spacer-sm;
		}

		.bb {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			width: 100%;

			& > *:not(:nth-last-child(1)) {
				margin-bottom: $spacer-sm;
			}
		}

		& > *:not(:nth-last-child(1)) {
			margin-bottom: $spacer-sm;
		}

		input, textarea {
			width: 100%;
			height: 44px;
			background-color: #F3F8F3;
			border: none;
			padding-left: $spacer;
			font-family: Sofia, sans-serif;
			color: $khaki;
			font-size: 12px;

			@include to($screen-xs) {
				width: 100%;
			}
		}

		input:focus {
			outline: 3px solid #5EB672;
		}

		textarea:focus {
			outline: 3px solid #5EB672;
		}

		#message {
			height: 114px;
			padding-top: $spacer-sm;
		}

		label {
			font-family: Lulo, sans-serif;
			text-align: left;
		}

		.disclaimer {
			font-family: "Lulo", sans-serif;
			font-size: 9px;
		}

		#anoniem-container, #newsletter-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 20px;

			input {
				width: fit-content;
				margin-right: $spacer-sm;
			}

			.check-label {
				font-family: "Lulo", sans-serif;
				font-size: 9px;
			}

			input {
				&:focus {
					outline: none;
				}

				&:checked {
					background-color: #5EB672;
					color: #5EB672;
				}
			}
		}

		#globalMessage {
			max-width: 500px;
			width: 100%;
			height: 120px;
			padding-top: $spacer-sm;
		}

		.o-section--checkout-global {
			width: 100%;
		}

		#newsletter-container {
			margin-top: $spacer-sm;
		}

		.required {
			font-family: "Lulo", sans-serif;
			font-size: 9px;
		}

		.m-form--contact-submit {
			margin-top: $spacer;
			font-family: Lulo, sans-serif;
			background-color: #5EB672;
			color: white;
			-webkit-appearance: none;
			-webkit-border-radius: none;
			border-radius: 20px;
			width: 50%;
			padding-left: unset;

			@include flexBox(column, center, center);
			cursor: pointer;
			transition: $transition-fast;

			&:hover {
				transition: $transition-fast;
				opacity: 0.5;
			}
		}
	}
}

.order-succes {
	font-family: "Taviraj", serif !important;
	font-weight: 300;
	font-size: 30px;
	line-height: 120%;
	color: #3D452B;
	margin-bottom: $spacer-sm;
}

.o-section-checkout-aggregate {
	width: fit-content;

	.section-title {
		font-family: "Lulo", sans-serif;
		align-self: flex-start;
		margin-bottom: $spacer;
	}

	.line {
		margin-top: $spacer-sm;
		width: 100%;
		border-bottom: 1px solid #3D452B;
	}

	.totaal {
		font-family: "Lulo", sans-serif;
		align-self: flex-end;
		margin-top: $spacer-sm;
	}

	.o-section-checkout-aggregate-wrapper {
		width: 100%;
		padding-bottom: unset;

		li {
			width: 100%;
			margin-bottom: $spacer-sm;
		}
	}
}


.share {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: $spacer;

	p {
		font-family: "Lulo", sans-serif;
		font-size: 14px;
		margin-right: 12px;
	}
}

#over-ons-subtext {
	font-size: 15px;
}

.wrapper {
	position: relative;
	width: 100%;

	.basket {
		position: absolute;
		display: flex;
		flex-direction: row-reverse;
		min-width:  100%;
		padding-top: 3.4em;
		padding-right: $spacer;

		@include to($screen-md) {
			display: none;
		}

		.cart-content {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			.amount {
				font-family: "Lulo", sans-serif;
				font-size: 14px;
				background-color: #ABD4AC;
				border-radius: 50%;
				height: $height;
				width: $height;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.total {
				padding-right: $spacer-sm;
				font-family: Taviraj, sans-serif;
			}

		}

		.a-image--default {
			height: 30px;
		}
	}
}

.t-layout--blog-feed-list {
	li {
		width: 100%;

		button {
			width: 100%;
		}
	}
}

.o-section--buy-control {
	padding: 0 $spacer-xl;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: $spacer;
	align-self: flex-end;

	@include to($screen-md) {
		justify-content: center;
		align-self: unset;
	}

	.o-section--buy-clear {
		font-family: "Lulo", sans-serif;
	}

	.o-section--cta-button {
		margin-left: $spacer;
		margin-top: unset;
	}
}
