@font-face {
	font-family: "Lulo";
	src: url("../fonts/Lulo_Clean_W01_One_Bold.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Sofia";
	src: url("../fonts/SofiaPro-Bold.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Sofia";
	src: url("../fonts/SofiaPro-Regular.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Taviraj";
	src: url("../fonts/taviraj-v6-latin-regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@import "./utilities/index.scss";

@import "./quarks/index.scss";
@import "./atoms/index.scss";
@import "./molecules/index.scss";
@import "./organisms/index.scss";
@import "./templates/index.scss";

p {
	color: $khaki;
	line-height: 135%;
}

.container {
	overflow: hidden;
	outline: none;
}


.canvas {
	position: relative;
	width: calc(1264px * 2);
	height: calc(1380px * 2);
	overflow: hidden;
}

.upper, .lower, .messages, .hover, .forsale {
	position: absolute;
	left: 0;
	top: 0;
	outline: none;
}

.lower {
	z-index: 0;
}

.upper {
	z-index: 1;
}

.messages {
	z-index: 4;
}

.hover {
	z-index: 3;
}

.forsale {
	z-index: 2;
}

.global-canvas {
	height: 62vh;
	overflow: hidden;
	margin-top: $spacer;
	background-color: #F3F8F3;

	@include to($screen-md) {
		margin-top: unset;
	}
}
