.t-transitions--fade-grow {
  transform: scale(0.95);
  opacity: 0;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 500ms;
  transition-delay: 1000ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
