.m-article {
	width: 100%;
	@include flexBox(row);
	text-align: left;

	@include to($screen-sm) {
		padding: $spacer-sm;
	}

	&[data-direction="left"] {
		@include flexBox(column, flex-start, flex-start);
	}

	&[data-direction="right"] {
		@include flexBox(column, flex-end, flex-end);
	}
}

.m-article--centered {
	@include flexBox(column, center center);
	text-align: center;
}

.m-article--hero {
	margin-top: $spacer-xl;
	width: 400px;
	z-index: $z-index-level-up;


	@include to($screen-sm) {
		width: 100%;
		padding: $spacer-sm;
	}

	h2 {
		font-family: "Taviraj", serif;
		font-weight: 300;
		font-size: 35px;
		line-height: 120%;
		color: #3D452B;
		margin-bottom: $spacer-sm;
	}
}

.m-article--section {
	width: 400px;

	.m-article--section-title {
		font-family: "Lulo", sans-serif;
		width: fit-content;
		position: relative;
		font-size: 35px;
		color: #4F8F47;
		margin-bottom: $spacer-xs;
		z-index: 1;

		&:after {
			content: '';
			position: absolute;
			bottom: 8px;
			left: -4%;
			width: 105%;
			height: 25%;
			background-color: #BACEAD;
			z-index: -1;
		}
	}

	.m-article--section-description {
		font-family: "Taviraj", serif;
		font-weight: 300;
		font-size: 22px;
		line-height: 120%;
		color: #3D452B;
		margin-bottom: $spacer-xs;
	}
}

.m-article--blog-card {
	background-color: #F9F9F5;
	max-width: $screen-md;
	padding: $spacer;
	display: flex;

	@include to($screen-md) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.m-article--blog-card-img {
		width: 60%;
		height: 25em;
		border-bottom-right-radius: 60px;

		@include to($screen-md) {
			width: 100%;
			height: 15em;
		}
	}

	.m-article--blog-card-content {
		align-self: flex-end;
		width: 40%;
		padding-left: $spacer;
		text-align: left;

		@include to($screen-md) {
			width: 100%;
			margin-top: $spacer;
			padding-left: unset;
		}

		h2 {
			font-family: Lulo, sans-serif;
			color: #5DB672;
			margin-bottom: $spacer-xs;
		}

		p {
			font-family: Sofia, sans-serif;
			margin-bottom: $spacer;
		}

		.tag {
			font-family: Lulo, sans-serif;
			color: #F7AC29;
			border: 1px solid #F7AC29;
			width: fit-content;
			padding: $spacer-xxs $spacer-xs;
			margin-bottom: $spacer-xs;
			font-size: 13px;
		}

		.about {
			font-family: Sofia, sans-serif;
			font-size: 12px;
			text-transform: uppercase;
			margin-bottom: unset;
			color: #797A60;
		}
	}
}

.m-article--section {
	@include to($screen-xs) {
		width: 100%;
		padding: unset;
	}
}

.m-article--section-title {
	@include to($screen-xs) {
		font-size: 20px !important;
	}
}

.m-list {
	list-style: none;
	padding-bottom: $spacer;

		@include flexBox(column, flex-start, flex-start);


	@include to($screen-md) {
		& > *:not(:nth-last-child(1)) {
			margin-bottom: $spacer-lg;
		}
	}
}

.m-article--buy-navigation {
	padding: $spacer $spacer-xl;
	background-color: #F9F9F5;
	display: grid;
	grid-template-columns: repeat(3, 33%);
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;

	@include to($screen-md) {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: flex-start;
	}

	@include to($screen-md) {
		padding: $spacer $spacer;
	}

	.o-section--item {
		margin-right: $spacer-lg;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-self: center;

		font-family: Sofia, sans-serif;
		font-size: 12px;
		text-align: left;
		line-height: 120%;
		font-weight: 400;

		.title {
			font-family: Lulo, sans-serif;
			font-size: 13.5px;
			line-height: 120%;
			margin-bottom: $spacer-sm;
		}

		.active {
			color: #5D9B3D;
		}

		&:last-child {
			margin-right: 0px;
		}

		@include to($screen-md) {
			margin-right: unset;
			margin-bottom: $spacer;
			align-items: flex-start;

			&:last-child {
				margin-bottom: unset;
			}
		}
	}
}

.m-article--buy-legend {
	width: 100%;
	padding: $spacer-lg $spacer-xl;

	@include to($screen-md) {
		padding: $spacer;
	}

	.line {
		width: 100%;
		border-bottom: 1px solid #3D452B;
	}

	.legend-item-container {
		display: grid;
		width: 50%;
		grid-template-columns: repeat(2, 1fr);
		row-gap: $spacer-sm;

		@include to($screen-md) {
			width: 100%;
		}

	}

	h3 {
		margin-top: $spacer;
		margin-bottom: $spacer;
		font-family: "Lulo", sans-serif;
		font-weight: 400;
		font-size: 13.5px;
	}

	.legend-item {
		display: flex;
		flex-direction: row;
		align-items: center;

		p {
			font-family: Sofia, sans-serif;
			font-size: 13.5px;
			text-align: left;
			line-height: 120%;
			font-weight: 400;
		}

		img {
			width: 35px;
			margin-right: $spacer-sm;
		}
	}
}

.m-article--checkout-listitem {
	width: 100%;
	display: grid;
	grid-template-columns: 10% 50% 15% 15% 10%;
	align-items: center;
	max-width: $screen-lg;

	@include to($screen-md) {
		display: flex;
		justify-content: space-between;
	}

	img {
		height: 40px;
	}

	p {
		font-family: Sofia, sans-serif;
		font-size: 13.5px;
		text-align: left;
		line-height: 120%;
		font-weight: 400;
	}

	.amount {
		font-family: "Lulo", sans-serif;
		font-weight: 400;
		text-align: right;
	}
}

.m-article--checkout-aggregateitem {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, auto);
	align-items: center;
	max-width: $screen-lg;
	column-gap: $spacer;

	img {
		height: 40px;
	}

	p {
		font-family: Sofia, sans-serif;
		font-size: 13.5px;
		text-align: left;
		line-height: 120%;
		font-weight: 400;
	}

	.amount {
		font-family: "Lulo", sans-serif;
		font-weight: 400;
		text-align: right;
	}
}
