@mixin size($width, $height) {
	width: $width;
	height: $height;
}

@mixin equalSize($size) {
	@include size($size, $size);
}

@mixin flexBox($direction:row,$justify:space-between,$align: center) {
	display: flex;
	flex-flow: $direction nowrap;
	justify-content: $justify;
	align-items: $align;
	text-align: center;
}

@mixin positionAbsolute($top:0, $left:0, $bottom: none) {
	@if $bottom != none {
		bottom: $bottom;
	} @else {
		top: $top;
	}

	left: $left;
	position: absolute;
}


@mixin positionFixed($top:0, $left:0, $bottom: none) {
	@if $bottom != none {
		bottom: $bottom;
	} @else {
		top: $top;
	}

	left: $left;
	position: fixed;
}

@mixin border($top: 0px, $right: 0px, $bottom: 0px, $left: 0px) {
	border-top: $top;
	border-right: $right;
	border-bottom: $bottom;
	border-left: $left;
}

@mixin border-radius($top: 0, $bottom: 0) {
	border-top-left-radius: $top;
	border-top-right-radius: $top;
	border-bottom-right-radius: $bottom;
	border-bottom-left-radius: $bottom;
}
@mixin border-radius-side($left: 0, $right: 0) {
	border-top-left-radius: $left;
	border-top-right-radius: $right;
	border-bottom-right-radius: $right;
	border-bottom-left-radius: $left;
}

@mixin preventSelect() {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	@include browserAccessibility(user-select, none);
}

@mixin keyframes($animationName) {
	@-webkit-keyframes #{$animationName} {
		@content;
	}
	@-moz-keyframes #{$animationName} {
		@content;
	}
	@-o-keyframes #{$animationName} {
		@content;
	}
	@keyframes #{$animationName} {
		@content;
	}
}

@mixin at($_min-width) {
	@media screen and (min-width: $_min-width) {
		@content;
	}
}

@mixin to($_max-width) {
	@media screen and (max-width: $_max-width) {
		@content;
	}
}

@function calculateRation($width,$ratioWidth:16, $ratioHeight:9){
	@return ($ratioHeight / $ratioWidth) * $width;
}

@mixin browserAccessibility($parameter,$val) {
	-webkit-#{$parameter} : $val;
	-moz-#{$parameter}: $val;
	-ms-#{$parameter}: $val;
	-o-#{$parameter} : $val;
	#{$parameter} : $val;
}
