.a-link {
	cursor: pointer;
	font-family: "Lulo", sans-serif;
	font-weight: 400;
	font-size: 13.5px;
}

.a-link--logo {
	height: 100%;
	position: relative;
}

.a-link--navigation {
	line-height: 120%;

	&.active,
	&:hover {
		color: #FFAE27;
	}
}

.a-link-cta {
	width: fit-content;
	font-size: 15px;
	position: relative;

	p {
		color: #5C9B3D;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0px;
		left: -4%;
		width: 105%;
		height: 50%;
		background-color: #ACD4AC;
		z-index: $z-index-background;
	}
}

.a-link--round {
	@include flexBox(column, center, center);
	border-radius: 30px;
	p {
		color: white;
	}

	width: 150px;
	height: 50px;
	background-color: white;
}
