* {
	margin: 0;
	padding: 0;
	left: 0;

	box-sizing: border-box;
}
html,
body {
	height: 100%;
	width: 100%;
}
html {
	font-size: 62.5%;
}

a {
	color: #000000;
	cursor: pointer;
	text-decoration: none;
}


img {
	object-fit: cover;
}

mark {
	background-color: transparent;
}

.reset-form {
	width: 100%;
	height: 100%;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

li {
	list-style: none;
}
