//.t-layout {
//	overflow-x: hidden;
//}

.t-layout--template {
	max-width: $screen-xx;
	margin: 0 auto;
	position: relative;
}

.t-layout--blog {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.blog-cards {
		margin-top: -$spacer-xl;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		li {
			width: 100%;
			margin-bottom: $spacer-lg;
		}

		@include to($screen-md) {
			width: 100%;
			margin-top: -16em;

		}
	}
}

.t-layout--buy-graphic-list {
	align-self: flex-end;
	width: 50%;
	margin-top: -$spacer-lg;
}

.t-layout--buy-graphic {
	align-self: flex-end;
	width: 50%;
	margin-top: -$spacer-xx;
}

.t-layout--buy-list {
	align-self: flex-end;
	width: 50%;
	margin-top: -$spacer-lg;
}

.t-layout--buy-checkoutcontainer {
	max-width: $screen-lg;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: $spacer-lg $spacer-xl;

	@include to($screen-sm) {
		padding: $spacer;
		margin-bottom: $spacer-xl;
		justify-content: center;
		align-items: center;

		flex-direction: column-reverse;
	}

}

.t-layout--buy {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow-x: unset;
}
