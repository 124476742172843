.a-dropdown--list-item {
	@include size(100%, $height);
	@include flexBox(row, flex-start);
	padding: $spacer-xs $spacer;

	cursor: pointer;
	transition: $transition-fast;

	&.selected {
		color: $white;
		-webkit-text-fill-color: currentColor;
	}

	&:focus,
	&:hover {
		outline: none;
	}
}

.a-dropdown--list {
	@include positionAbsolute($height);

	overflow-y: auto;
	box-shadow: $box-shadow;
	border: $border;
	border-top: none;
	@include border-radius($bottom: $border-radius);

	background: $white;
	z-index: $z-index-drop-down;

	&:focus,
	&:hover {
		outline: none;
	}
}

.a-dropdown--list-default {
	width: 100%;
	max-height: calc(#{$height} * 4.6);
}


///////// new
.a-dropdown {
	@include size(100%, $height);
	position: relative;

	font-family: Lulo, sans-serif;
	font-size: 11px;

	&:focus,
	&:focus-within,
	&:hover {
		border-width: 2px;
		outline: none;

		.a-dropdown--list-wrapper {
			border-width: 2px;
			@include positionAbsolute(calc(#{$height} - 4px), -2px);
		}
	}

	&.disabled {
		cursor: not-allowed;

		&:focus,
		&:focus-within,
		&:hover {
			border-width: 1px;

			.a-dropdown--list-wrapper {
				border-width: 1px;
			}
		}
	}
}

.a-dropdown--icon-header {
	border: none;
	@include flexBox(row, flex-end);
}

.a-dropdown-header {
	@include size(100%, $height);
	padding: $spacer-sm;
	@include flexBox();
	justify-content: flex-end;

	cursor: pointer;

	@include to($screen-sm) {
		justify-content: flex-start;
	}

	.placeholder {
		color: $grey;
		-webkit-text-fill-color: currentColor;
	}

	&:focus,
	&:hover {
		.icon-arrow--down {
			animation: move $transition-lazy $delay-short infinite;

			@include keyframes(move) {
				0%, 100% { transform: translateY(25%) rotate(90deg) }
				50% { transform: translateY(50%) rotate(90deg) }
			}
		}
	}

	&.disabled {
		cursor: not-allowed;

		.icon-arrow--down {
			animation: none;
		}
	}
}

.a-dropdown-header--language-picker {
	$picker-width: $width-sm;
	@include size($picker-width, $height);
	position: relative;
	text-align: right;

	.language-picker--header {
		padding: $spacer-sm 0;
		padding-left: $spacer-xs;
		outline: none;
		text-align: right;

		@include to($screen-sm) {
			padding-left: unset;
		}

		&.active {
			p {
				color: #5EB672;

			}
		}
	}

	.language-picker--list {
		left: 100%;
		width: $picker-width;
		z-index: $z-index-overlay;
		@include positionAbsolute(-$height);
	}
}

.a-dropdown-icon-header {
	@include equalSize($height);
	@include flexBox(column, center);
	position: relative;

	&.small {
		@include equalSize($height-sm);
	}

	&:focus,
	&:hover {background: $grey--light;
	}
}

.a-dropdown-list {
	width: 100%;
	max-height: calc(#{$height-lg} * 5.25);
	@include positionAbsolute(calc(#{$height} - 2px), -1px);
	box-sizing: content-box;
	background: $white;
	overflow: auto;

	border: $border;
	@include border-radius($bottom: $border-radius);
	z-index: $z-index-drop-down;
}

.a-dropdown-list-icon {
	width: max-content;
	max-height: unset;
	left: calc(100% - #{$spacer-sm});
	box-shadow: 0 0 0px 0px rgba($black, 0.5);
	border: $border;
	border-radius: $border-radius;

	transform: translate(-100%);
}

.dropdown {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
}
