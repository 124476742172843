.a-dropdown-list-item {
	@include size(100%, $height);
	@include flexBox(row, flex-end);
	padding: $spacer-sm;
	position: relative;
	text-align: right;

	list-style: none;
	cursor: pointer;
	background: $white;

	&.active {
		& > * {
		}
		background: #F3F8F3;
	}

	&:focus,
	&:focus-within,
	&:hover {
		outline: none;
		background: rgba(#5EB672, 0.2);
	}
}
