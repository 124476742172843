.a-quote-default {
	font-family: Taviraj, sans-serif;
	text-align: left;
	display: flex;
	flex-direction: row;
	align-items: flex-start;


	.quote {
		width: 22px;
		object-fit: contain;
		padding-top: $spacer-sm;
		margin-right: $spacer-sm;
	}
}
